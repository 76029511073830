/* DSEG7 7SEG */

@font-face {
  font-family: 'DSEG7-7SEGG';
  src: url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHAN-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHAN-Regular.woff') format('woff'),
  url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHAN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-7SEGG-MINI';
  src: url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHANMINI-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHANMINI-Regular.woff') format('woff'),
  url('../fonts/DSEG7-7SEGG-CHAN/DSEG7SEGGCHANMINI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG7 Classic */

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-Bold.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Bold.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-Italic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Italic.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-Light.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Light.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-LightItalic.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic';
  src: url('../fonts/DSEG7-Classic/DSEG7Classic-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Regular.woff') format('woff'),
  url('../fonts/DSEG7-Classic/DSEG7Classic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG7 Classic MINI */

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Bold.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Bold.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Italic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Italic.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Light.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Light.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-LightItalic.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Classic-MINI';
  src: url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Regular.woff') format('woff'),
  url('../fonts/DSEG7-Classic-MINI/DSEG7ClassicMini-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG7 Modern */

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-Bold.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Bold.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-Italic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Italic.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-Light.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Light.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-LightItalic.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern';
  src: url('../fonts/DSEG7-Modern/DSEG7Modern-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Regular.woff') format('woff'),
  url('../fonts/DSEG7-Modern/DSEG7Modern-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG7 Modern MINI */

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Bold.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Bold.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Italic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Italic.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Light.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Light.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-LightItalic.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG7-Modern-MINI';
  src: url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Regular.woff2') format('woff2'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Regular.woff') format('woff'),
  url('../fonts/DSEG7-Modern-MINI/DSEG7ModernMini-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG14 Classic */

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-Bold.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Bold.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-Italic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Italic.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-Light.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Light.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-LightItalic.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic';
  src: url('../fonts/DSEG14-Classic/DSEG14Classic-Regular.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Regular.woff') format('woff'),
  url('../fonts/DSEG14-Classic/DSEG14Classic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG14 Classic MINI */

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Bold.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Bold.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Italic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Italic.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Light.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Light.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-LightItalic.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Classic-MINI';
  src: url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Regular.woff2') format('woff2'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Regular.woff') format('woff'),
  url('../fonts/DSEG14-Classic-MINI/DSEG14ClassicMini-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG14 Modern */

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-Bold.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Bold.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-Italic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Italic.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-Light.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Light.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-LightItalic.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern';
  src: url('../fonts/DSEG14-Modern/DSEG14Modern-Regular.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Regular.woff') format('woff'),
  url('../fonts/DSEG14-Modern/DSEG14Modern-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEG14 Modern MINI */

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Bold.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Bold.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-BoldItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-BoldItalic.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Italic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Italic.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Light.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Light.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-LightItalic.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-LightItalic.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DSEG14-Modern-MINI';
  src: url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Regular.woff2') format('woff2'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Regular.woff') format('woff'),
  url('../fonts/DSEG14-Modern-MINI/DSEG14ModernMini-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* DSEGWeather */

@font-face {
  font-family: 'DSEGWeather';
  src: url('../fonts/DSEGWeather/DSEGWeather.woff2') format('woff2'),
  url('../fonts/DSEGWeather/DSEGWeather.woff') format('woff'),
  url('../fonts/DSEGWeather/DSEGWeather.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


